<template>
  <!-- 课程大纲 -->
  <div class="main-contain">
    <div class="item-contain">
      <template v-if="courseinfo && courseinfo.length > 0">
        <div class="item" v-for="(item0, index) in courseinfo" :key="index">
          <div class="item-title">
            <div class="item-title-text">{{ item0.number }}</div>
            <div class="item-title-text rt">{{ item0.name }}</div>
          </div>
          <span v-if="index == 0" class="yl">登录后可预览视频</span>
          <template v-if="item0.children && item0.children.length > 0">
            <div
              class="item-item"
              v-for="(item1, index) in item0.children"
              :key="index"
            >
              <div class="item-item-icon"></div>
              <div class="item-item-num">{{ item1.number }}</div>
              <div class="item-item-text">{{ item1.name }}</div>
              <!-- <div class="item-item-text">1231234</div> -->
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getoutlinelist } from "@/api/outline";
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      courseinfo: "",
    };
  },
  methods: {
    initdata(obj) {
      this.getoutlinelist({
        course_sn: obj.course_sn,
      });
    },
    getoutlinelist(params) {
      getoutlinelist(params)
        .then((response) => {
          this.courseinfo = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  .item-contain {
    // border: 1px solid red;
    margin-top: 50px;
    margin-left: 20px;
    padding-bottom: 50px;
    // height: 100px;
    .item {
      margin-top: 20px;
      .item-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item-title-text {
          margin-right: 30px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #666666;
        }
      }
      .item-item {
        margin-top: 20px;
        margin-left: 90px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item-item-icon {
          width: 12px;
          height: 12px;
          background: #c6c6d3;
          border-radius: 50%;
        }
        .item-item-num {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .item-item-text {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
      .rt{
        overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 70%;
      }
      .yl{
          text-align: right;
          font-size: 14px;
          color: #E65C30;
          float: right;
          margin-top: -22px;
      }
    }
  }
}
</style>
